#hero-section-operational-support {
    height: 50vh;

    background: url(../images/operational-support.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
}

#hero-title-operational-support {
    margin-top: -5vw;
    
    color: var(--clr-primary-600);
    font-size: var(--fs-650);
}


#advisory-section-divider{
    position: absolute;
    margin-top: -14vw;
}

#advisory-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-400);
}

#advisory-section-container{
    padding-top: 8vw;
    padding-bottom: 16vw;
}

#advisory-section-case-container{
    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#advisory-section-header {
    color: var(--clr-neutral-100);
    font-size: var(--fs-400);

    padding-bottom: 2rem;
}

#advisory-section-text {
    font-size: var(--fs-300);

    padding-bottom: 2rem;

    text-align: center;
}

#advisory-section-image {
    background-color: var(--clr-neutral-100);
    padding: 1rem;
    border-radius: 1rem;
}

#tactical-implementation-section-divider{
    position: absolute;
    margin-top: -14vw;
}

#tactical-implementation-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-600);
}

#tactical-implementation-section-container{
    padding-top: 8vw;
    padding-bottom: 16vw;

    display: flex;
    justify-content: center;
}

#tactical-implementation-section-case-container{
    padding-left: 1rem;
    padding-right: 1rem;
}

#tactical-implementation-section-header {
    color: var(--clr-neutral-100);
    font-size: var(--fs-400);

    padding-bottom: 2rem;
}

#tactical-implementation-section-image {
    width: 50%;
    float: left;
    margin-right: 1rem;

    background-color: var(--clr-neutral-100);

    padding: 1rem;
    border-radius: 1rem;
}

#tactical-implementation-section-text {
    font-size: var(--fs-300);
}



#ops-section-divider{
    position: absolute;
    margin-top: -14vw;
}

#ops-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-800);
}

#ops-section-container{
    padding-top: 8vw;
    padding-bottom: 16vw;

    display: flex;
    justify-content: center;
}

#ops-section-case-container{
    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#ops-section-header {
    color: var(--clr-neutral-100);
    font-size: var(--fs-400);

    padding-bottom: 2rem;
}

#ops-section-text {
    padding-bottom: 2rem;

    font-size: var(--fs-300);
}

#ops-section-image {
    background-color: var(--clr-neutral-100);
    padding: 1rem;
    border-radius: 1rem;

    float: left;
    margin-right: 1rem;
}

@media screen and (min-width: 450px) {
    #hero-title-operational-support {
        font-size: var(--fs-900);
    }

    #advisory-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    
    #advisory-section-header {
        font-size: var(--fs-700);
    }
    
    #advisory-section-text {
        font-size: var(--fs-600);
    }

    #advisory-section-image {
        width: 60%;
    }

    #tactical-implementation-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    #tactical-implementation-section-header {
        font-size: var(--fs-700);
    }
    
    #tactical-implementation-section-text {
        font-size: var(--fs-600);
    }

    #ops-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    #ops-section-header {
        font-size: var(--fs-700);
    }
    
    #ops-section-text {
        font-size: var(--fs-600);
    }

    #ops-section-image {
        width: 80%;
    }
}

@media screen and (min-width: 1000px) {
    #advisory-section {
        padding-left: 8rem;
        padding-right: 8rem;
    }


    #tactical-implementation-section {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    #ops-section {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}