#contact-button {
    font-family: var(--ff-accent);
    font-size: var(--fs-700);
    color: var(--clr-neutral-100);

    border: none;
    background-color: var(--clr-primary-600);

    padding-left: 1rem;
    padding-right: 1rem;
}

#contact-button:hover {
    background-color: var(--clr-primary-400);
    cursor: pointer;
}

#contact-button:active {
    background-color: var(--clr-primary-800);
}

@media screen and (max-width: 570px) {
    #contact-button {
        font-size: var(--fs-700);
    }
}

@media screen and (max-width: 500px) {
    #contact-button {
        font-size: var(--fs-600);
    }
}