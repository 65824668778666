/* reset */
form input, form textarea, form button {
    border: none;
    /* color: white; */
}

#form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

form {
    margin: 2rem;
    padding: 1rem;
    width: 500px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    /* background-color: var(--clr-primary-100); */
    border: 1px solid var(--clr-primary-600);
    border-radius: 0.5rem;
    color : var(--clr-primary-600);
}

form input {
    margin-bottom: 1rem;
    padding: 0rem;
    padding-left: 0.5rem;

    border: 1px solid var(--clr-primary-100);
    color: var(--clr-primary-100);
    outline: none;
}



form button {
    margin-top: 1rem;

    font-family: var(--ff-accent);
    color: var(--clr-primary-600);
    border: 1px solid var(--clr-primary-100);
    background-color: var(--clr-neutral-100);
    border-radius: 0.25rem;
}

form button:hover {
    background-color: var(--clr-primary-100);
    color: var(--clr-primary-600);

    cursor: pointer;
}

form button:active {
    background-color: var(--clr-primary-800);
    color: var(--clr-primary-600);
}

