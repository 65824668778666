#hero-section-strategic-consultancy {
    height: 50vh;

    background: url(../images/strategic-consultancy2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
}

#hero-title-strategic-consultancy {
    margin-top: -5vw;
    
    color: var(--clr-primary-600);
    font-size: var(--fs-650);
}


.case-section-divider{
    position: absolute;
    margin-top: -14vw;
}

.case-header-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.case-header-image {
    background-color: var(--clr-neutral-100);
    border-radius: 1rem;
    margin-bottom: 1rem;
}


.case-section-subheader {
    color: var(--clr-neutral-100);
}

.case-section-sub-subheader {
    font-weight: var(--fw-thin);
}

.italic {
    font-style: italic;
}

.case-subsection-divider {
    margin-top: 1rem;
    margin-bottom: 1rem;

    border-top: 1px solid var(--clr-neutral-100);
    border-bottom: none;
}

#accordion-2-container {
    background-color: var(--clr-primary-400);
}

#accordion-3-container {
    background-color: var(--clr-primary-600);
}

.MuiButtonBase-root {
    border: 1px solid red;
}

@media screen and (min-width: 600px) {
    #hero-title-strategic-consultancy {
        font-size: var(--fs-900);
    }
}