.swiper {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 1rem;
    max-width: 500px;
    height: 100%;

    overflow: hidden;
  }
  
.swiper-slide {
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--clr-neutral-100);
  }

.case-carousel-section-header {
    color: var(--clr-primary-600);
    font-size: var(--fs-400);
    padding-bottom: 1rem;
}

.case-carousel-section-text {
    color: var(--clr-neutral-1000);
    font-size: var(--fs-300);
}

.case-carousel-section-text-summary {
    color: var(--clr-primary-600);
}

@media screen and (min-width: 600px) {
    #swiper-container {
        display: flex;
        justify-content: center;
    }
}