#log-mobile-container {
  display: none;
}

#logo-mobile {
  height: 2rem;
}

.navbar {
    background: var(--clr-primary-600);
    height: 5rem;
    display: flex;
    align-items: center;

    position: sticky;
    top: 0;

    z-index: 2;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .logo {
    height: 2rem;
  }

  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;

    z-index: 2;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
  }
  
  .nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: var(--clr-primary-600);
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  #services-nav-link {
    cursor: default;
  }

  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    #log-mobile-container {
      background-color: var(--clr-primary-600);

      justify-content: center;
      padding-top: 1rem;
    
      position: sticky;
      top: 0;    
      
      display: flex;

      z-index: 2;
    }
    
    .navbar {
      justify-content: space-between;

      top: 3rem;

      z-index: 2;
    }
    
    .navbar-logo-container {
      display: none;
    }

    #menu-button {
      margin-left: 2rem;
    }
    
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: -35rem;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: var(--clr-primary-600);
      top: 5rem;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 2;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: var(--clr-primary-400);
      border-radius: 0;
    }

    .navbar-logo-container {
      position: absolute;
      left: 2rem;
      margin-left: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      right: 2rem;
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: var(--clr-neutral-100);
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: var(--clr-primary-600);
      text-decoration: none;
      color: var(--clr-neutral-100);
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: var(--clr-primary-600);
      color: var(--clr-primary-600);
      transition: 250ms;
    }
  }