#menu-button {
    font-family: var(--ff-accent);
    font-size: var(--fs-600);
    color: var(--clr-primary-600);

    border: none;
    background-color: var(--clr-neutral-100);

    margin-right: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

#menu-button:hover {
    background-color: var(--clr-primary-100);

    cursor: pointer;
}

#menu-button:active {
    background-color: var(--clr-primary-800);
}

@media screen and (max-width: 960px) {
    #menu-button {
        margin-right: 0;
    }
}