/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

html,
body {
  height: 100%;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

body {
  margin: 0;
  font-family:'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom CSS */

:root {
  --clr-primary-800: #E65C00;
  --clr-primary-600: #FF6600;
  --clr-primary-400: #FF8433;
  --clr-primary-100: #ffcba8;

  --clr-neutral-100: white;
  --clr-neutral-500: rgb(127.5, 127.5, 127.5);
  --clr-neutral-1000: black;

  --ff-primary: 'Montserrat', sans-serif;
  --ff-body: var(--ff-primary);
  --ff-accent: var(--ff-primary);

  --fw-regular: 300;
  --fw-thin: 100;
  --fw-bold: 700;

  --fs-200: 0.7rem;
  --fs-300: .85rem;
  --fs-400: 1rem;
  --fs-600: 1.4rem;
  --fs-650: 1.6rem;
  --fs-700: 1.875rem;
  --fs-900: 2.5rem;

  --bg: #FF6600;
  --text-color: #dadce1;
  --navbar-size: 60px;
  --border: 1px solid #FF8433;
  --border-radius: 8px;
  --speed: 500ms;
}

a {
  text-decoration: none;
  color: var(--text-color)
}