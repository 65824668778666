#introduction-section-divider{
    position: absolute;
    margin-top: -14vw;
}

#introduction-section-content-container{
    background-color: var(--clr-primary-400);

    display: flex;
    justify-content: center;
}

#introduction-section-content {
    padding-top: 8vw;
    padding-bottom: 16vw;
    padding-left: 1rem;
    padding-right: 1rem;
}

#introduction-section-header {
    color: var(--clr-neutral-100);
    font-weight: var(--fw-regular);
    font-size: var(--fs-600);
}

#introduction-section-subheader {
    color: var(--clr-neutral-1000);
    font-weight: var(--fw-regular);
    font-size: var(--fs-400);

    margin-bottom: 2rem;
}

.introduction-section-text-title {
    color: var(--clr-neutral-100);
}

.introduction-section-text {
    font-size: var(--fs-300);
    margin-bottom: 1rem;
}

#case-section-divider {
    position: absolute;
    margin-top: -14vw;
}

#case-section {
    padding-top: 8vw;
    padding-bottom: 16vw;

    background-color: var(--clr-primary-600);
}

#case-carousel-section-link-section {
    display: flex;
    justify-content: center;
}

#case-carousel-section-link {
    color: var(--clr-neutral-100);
    font-size: var(--fs-600);
}

#case-carousel-section-link:hover {
    color: rgba(255, 255, 255, 0.5);
}

@media screen and (min-width: 500px) {
    #introduction-section-content {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    
    #introduction-section-header {
        font-size: var(--fs-700);
    }
    
    #introduction-section-subheader {
        font-size: var(--fs-600);
    }

    .introduction-section-text {
        font-size: var(--fs-400);
    }
}

@media screen and (min-width: 1050px) {
    #introduction-section-content {
        padding-left: 8rem;
        padding-right: 8rem;
    }
    
    #introduction-section-header {
        font-size: var(--fs-900);
    }
    
    #introduction-section-subheader {
        font-size: var(--fs-700);
    }

    .introduction-section-text {
        font-size: var(--fs-600);
    }
}