#about-hero-section {
    height: 35vw;

    background: url(../images/about2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    display: flex;
    justify-content: center;
    align-items: center;
}

#about-hero-title {
    margin-top: -5vw;
    
    color: var(--clr-primary-600);
    font-size: var(--fs-650);
}

#motto-section-divider {
    position: absolute;
    margin-top: -14vw;
}

#motto-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-100);
}

#motto-section-text {
    text-align: center;
    padding-top: 8vw;
    padding-bottom: 16vw;
}

#motto-section-highlight {
    color: var(--clr-primary-600);
}

#philosophy-section-divider {
    position: absolute;
    margin-top: -14vw;
}
#philosophy-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-400);
}

#philosophy-section-content {
    text-align: center;
    padding-top: 8vw;
    padding-bottom: 16vw;
}

#philosophy-section-header {
    color: var(--clr-neutral-100);
    font-weight: var(--fw-thin);
    font-size: var(--fs-600);
}

.philosophy-section-text {
    padding-top: 2rem;
}

.white-highlight {
    color: var(--clr-neutral-100);
}

#name-section-divider {
    position: absolute;
    margin-top: -14vw;
}
#name-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-600);
}

#name-section-content {
    text-align: center;
    padding-top: 8vw;
    padding-bottom: 16vw;
}

#name-section-header {
    color: var(--clr-neutral-100);
    font-weight: var(--fw-thin);
    font-size: var(--fs-600);
}

.name-section-text {
    padding-top: 2rem;
}

#people-section-divider {
    position: absolute;
    margin-top: -14vw;
}

#people-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-800);
}

#people-section-content {
    background-color: var(--clr-primary-800);
    padding-top: 8vw;
    padding-bottom: 16vw;

    display: grid;
    grid-template: 1fr 1fr 1fr 1fr / 1fr;
    row-gap: 2rem;
}

.person-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.person-image {
    width: 30vw;
}

.person-text-container {
    color: var(--clr-neutral-100);
    font-size: var(--fs-400);
}

.person-name {
    font-weight: var(--fw-regular);
}

.person-title {
    font-weight: var(--fw-thin);
    padding-left: 1rem;
}

@media screen and (min-width: 600px) {
    #about-hero-title {
        font-size: var(--fs-900);
    }
    #motto-section {
        font-size: var(--fs-600);
    }

    #motto-section-text {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    #philosophy-section-header {
        font-size: var(--fs-700)
    }

    .philosophy-section-text {
        font-size: var(--fs-600);
        padding-left: 2rem;
        padding-right: 2rem;    
    }

    #name-section-header {
        font-size: var(--fs-700)
    }

    .name-section-text {
        font-size: var(--fs-600);
        padding-left: 2rem;
        padding-right: 2rem;  
    }

    #people-section-content {
        display: grid;
        grid-template: 1fr 1fr / 1fr 1fr;
        row-gap: 2rem;
        column-gap: 2rem;
    }

    .person-text-container {
        font-size: var(--fs-600);
    }

    .person-image {
        width: 20vw;
    }
}

@media screen and (min-width: 1000px) {
    #motto-section-text {
        padding-left: 8rem;
        padding-right: 8rem;  
    }


    .philosophy-section-text {
        padding-left: 8rem;
        padding-right: 8rem;  
    }


    .name-section-text {
        padding-left: 8rem;
        padding-right: 8rem;  
    }
}