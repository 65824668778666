#hero-section {
    height: 75vw;

    animation: animate 10s ease-in-out infinite;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;

    display: flex;
    justify-content: right;
}

@keyframes animate {
    0% {
        background-image: url(../images/father2.png);
    }
    50% {
        background-image: url(../images/hot-cell.png);
    }
    100% {
        background-image: url(../images/meeting-doctor-small.png);
    }
}

#hero-container {
    width: 67%;

    font-size: var(--fs-400);
    font-weight: var(--fw-regular);

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#hero-content-container {
    background-color: rgba(255, 255, 255, .5);
    border-radius: 0.5rem;
    padding: 1rem;
}

#hero-text-container {
    margin-bottom: 1rem;
}

.hero-text {
    color: var(--clr-primary-600);
}

@media screen and (min-width: 500px) {
    #hero-container {
        font-size: var(--fs-700);
    }
}

@media screen and (min-width: 1050px) {
    #hero-section {
        height: 40vw;
    }
    
    #hero-container {
        font-size: var(--fs-900);
    }
}