#contact-section-divider {
    transform: rotate(180deg);
}

#confirmation-text {
    margin: 1rem;
    text-align: center;

    color: var(--clr-primary-600);
    font-size: var(--fs-600);
}

#thank-you-text {
    margin: 1rem;
    text-align: center;

    color: var(--clr-primary-600);
    font-size: var(--fs-400);
}

#booking-page-rarevox-highlight {
    color: var(--clr-neutral-1000);
}

#booking-instruction-text {
    margin: 1rem;
    text-align: center;

    color: var(--clr-primary-400);
    font-size: var(--fs-400);
}

.calendly-inline-widget{
    min-width:320px;
    height:700px;
}