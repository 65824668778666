footer {
    font-size: var(--fs-200);
    padding: 1rem;
}

#footer-content-container {
    display: flex;
    flex-direction: column;
}

#footer-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    text-decoration: none;
}

#services-nav {
    list-style-type: none;
}

.footer-nav-title {
    color: var(--clr-primary-800);
}

.footer-nav-subtitle {
    color: var(--clr-primary-100);
}

#footer-logo-container {
    display: flex;
    justify-content: center;
}

#footer-logo {
    width: 5rem;
    margin-bottom: 1rem;

    font-family: var(--ff-primary);
}

#footer-divider {
    height: 1px;

    background-color: var(--clr-primary-100);
    border: 0;
}

#copyright-notice {
    margin-top: 1rem;
    
    text-align: center;

    color: var(--clr-primary-100);
}

#inexora-link {
    color: rgb(0, 153, 255, 0.3);

}

@media screen and (min-width: 600px) {
    footer {
        font-size: var(--fs-400);
        padding-right: 3rem;
        padding-left: 3rem;
    }
    
    #footer-content-container {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    
    #footer-logo-container {
        order: 1;
        margin-right: 1rem;
    }

    #footer-logo {
        width: 10rem;
        margin-bottom: 0;
    }

    #footer-nav {
        order: 2;
        width: 100%;
        justify-content: space-between;
    }
    
}

@media screen and (min-width: 1100px) {
    footer {
        font-size: var(--fs-400);
        padding-right: 10rem;
        padding-left: 10rem;
    }
}