#hero-section-strategic-consultancy {
    height: 50vh;

    background: url(../images/strategic-consultancy2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
}

#hero-title-strategic-consultancy {
    margin-top: -5vw;
    
    color: var(--clr-primary-600);
    font-size: var(--fs-650);
}


#strategic-integration-section-divider{
    position: absolute;
    margin-top: -14vw;
}

#strategic-integration-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-400);
}

#strategic-integration-section-container{
    padding-top: 8vw;
    padding-bottom: 16vw;

    display: flex;
    justify-content: center;
}

#strategic-integration-section-case-container{
    padding-left: 1rem;
    padding-right: 1rem;
}

#strategic-integration-section-header {
    color: var(--clr-neutral-100);
    font-size: var(--fs-400);

    padding-bottom: 2rem;
}

#strategic-integration-section-text {
    font-size: var(--fs-300);
}

#strategic-integration-section-image {
    background-color: var(--clr-neutral-100);
    padding: 0.5rem;
    border-radius: 1rem;

    width: 40%;
    float: right;
    margin-left: 1rem;
}

#advocacy-section-divider{
    position: absolute;
    margin-top: -14vw;
}

#advocacy-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-600);
}

#advocacy-section-container{
    padding-top: 8vw;
    padding-bottom: 16vw;

    display: flex;
    justify-content: center;
}

#advocacy-section-case-container{
    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#advocacy-section-header {
    color: var(--clr-neutral-100);
    font-size: var(--fs-400);

    padding-bottom: 2rem;

    text-align: center;
}

#advocacy-section-text {
    font-size: var(--fs-300);
    
    text-align: center;

    padding-bottom: 2rem;
}

#advocacy-section-image {
    background-color: var(--clr-neutral-100);

    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 1rem;
}

#strategic-alliance-section-divider{
    position: absolute;
    margin-top: -14vw;
}

#strategic-alliance-section {
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: var(--clr-primary-800);
}

#strategic-alliance-section-container{
    padding-top: 8vw;
    padding-bottom: 16vw;

    display: flex;
    justify-content: center;
}

#strategic-alliance-section-case-container{
    padding-left: 1rem;
    padding-right: 1rem;
}

#strategic-alliance-section-header {
    color: var(--clr-neutral-100);
    font-size: var(--fs-400);

    padding-bottom: 2rem;
}

#strategic-alliance-section-image {
    width: 50%;

    background-color: var(--clr-neutral-100);
    padding: 1rem;
    border-radius: 1rem;

    float: left;
    margin-right: 1rem;
}

#strategic-alliance-section-text {
    font-size: var(--fs-300);
}

@media screen and (min-width: 450px) {
    #hero-title-strategic-consultancy {
        font-size: var(--fs-900);
    }

    #strategic-integration-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    
    #strategic-integration-section-header {
        font-size: var(--fs-700);
    }
    
    #strategic-integration-section-text {
        font-size: var(--fs-600);
    }

    #strategic-integration-section-image {
        width: 25%;
    }

    #advocacy-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    #advocacy-section-header {
        font-size: var(--fs-700);
    }
    
    #advocacy-section-text {
        font-size: var(--fs-600);
    }

    #advocacy-section-image {
        width: 60%;
    }

    #strategic-alliance-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    #strategic-alliance-section-header {
        font-size: var(--fs-700);
    }
    
    #strategic-alliance-section-text {
        font-size: var(--fs-600);
    }

    #strategic-alliance-section-image {
        width: 30%;
    }
}

@media screen and (min-width: 1000px) {
    #strategic-integration-section {
        padding-left: 8rem;
        padding-right: 8rem;
    }


    #advocacy-section {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    #strategic-alliance-section {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}